import { staticURLs } from './staticURL';
import { urls, baseUrl } from './url';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false,
  host: `${baseUrl}`,
  s3Path:'https://trade-waste-public-storage.s3.amazonaws.com',
  // alarmPermitHost: 'https://ap.appening.xyz',
  alarmPermitHost: 'https://ap.appening.xyz/',
  cannabisHost: 'https://cb.appening.xyz/',
  busniessHost: 'https://bl.appening.xyz/',

  dogUrl:'https://dog.appening.xyz/',
  ceUrl:'https://coop-react.appening.xyz/',


  ...urls,
  ...staticURLs
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
