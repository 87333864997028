import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { appToaster } from 'src/app/configs';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { AdminService } from 'src/app/core/services/admin/admin.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import * as CryptoJS from "crypto-js";
@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css']
})
export class AdminHeaderComponent implements OnInit, OnDestroy {

  public currentUser;
  public isApplicationEditable = false;
  public signatureFileName:string ;
  public isSignatureUploaded = false;
  public selectedFile: any;
  public isSubmit = false;
  public errorMessage = '';
  public hideModal = false;
  public href:any;

  @ViewChild('signatureUpl', { static: false }) signatureUpl: ElementRef;

  private subscription: Subscription = new Subscription();

  constructor(public authenticationService: AuthenticationService, private toasterService: ToastrService, private router: Router, private adminService: AdminService,private route: ActivatedRoute, private location: Location) { }
  decryptedData: any;
  secretKey = "mySecretKey123";
  encryptedData: any;
  isActiveAp = true;
  isActiveBl = true;
  isActiveCB = true;
  isActiveTW = true;
  isActiveDG = true;
  isActiveCE = true;
  projectUrl: any;

  ngOnInit() {
    this.interval() ;
    this.subscription.add(this.route.queryParams.subscribe((data: any) => {
      console.log("data is ================>",data.encryptedData)
      if (data && data.encryptedData) {
        this.encryptedData = data.encryptedData;
        sessionStorage.setItem('encryptedData', data.encryptedData);
        const [path, query] = this.location.path().split('?');
        const params = new HttpParams({ fromString: query });
          this.location.replaceState(path, params.delete('encryptedData').toString());

      }
      // if (data && data.csrfToken && data.encryptedData) {
      //   this.subscription.add(this.applicationService.verifyCSRFToken({ token: data.csrfToken }).subscribe((data: any) => {
      //     this.authenticationService.loginDetailsBs.next(data.response);
      //     this.authenticationService.loggedOutBs.next(false);
      //     this.getCityList();
      //     this.getUserDeatil();
      //     this.applicationService.setSaveAndExit(false);
      //     const [path, query] = this.location.path().split('?');
      //     const params = new HttpParams({ fromString: query });
      //     this.location.replaceState(path, params.delete('csrfToken').toString());
      //   }));
      // } else {
       
      // }
    }));
    if (sessionStorage.getItem('encryptedData')) {
      this.loginData();
    }
  }
  loginData() {
    this.encryptedData = sessionStorage.getItem('encryptedData');
      const stringWithoutPlus = this.encryptedData.replace(/ /g, "+");
      const decryptedBytes = CryptoJS.AES.decrypt(
        stringWithoutPlus,
        this.secretKey
      );
      let decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
      this.decryptedData = JSON.parse(decryptedData);
      console.log("decryptedData", this.decryptedData);
      debugger;
      if (this.decryptedData) {
        this.checkLoginStatus();
      }
    }
    checkLoginStatus() {
      this.isActiveAp = false;
      this.isActiveBl = false;
      this.isActiveCB = false;
      this.isActiveTW = false;
      this.isActiveDG = false;
      this.isActiveCE =  false;
      if (this.decryptedData.login_by == 1 || this.decryptedData.login_by == 3) {
        this.isActiveAp = true;
        this.isActiveBl = true;
        this.isActiveCB = true;
        this.isActiveTW = true;
        this.isActiveDG = true;
        this.isActiveCE = true;
        return true;
      }
      if (this.decryptedData.login_by == 2) {
        if (this.decryptedData.status == 1) {
          this.isActiveAp = true;
        }
        if (this.decryptedData.bl_status == 1) {
          this.isActiveBl = true;
        }
        if (this.decryptedData.tw_status == 1) {
          this.isActiveTW = true;
        }
        if (this.decryptedData.cb_status == 1) {
          this.isActiveCB = true;
        }
        if (this.decryptedData.dg_status == 1) {
          this.isActiveDG = true;
        }
        if (this.decryptedData.coop_status == 1) {
          this.isActiveCE= true;
        }
      }
    }
    url:any;
    switchToOtherPortal(val) {
      // this.authenticationService.logout();
      const encryptedData =  sessionStorage.getItem('encryptedData');
      const csrfToken = sessionStorage.getItem('csrfToken');
  
      console.log("csrf token ===>", csrfToken);
      if (val == 2) {
        // environment.alarmPermitHost
        (window.location.href = `${environment.alarmPermitHost}/admin/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`),
          "_blank";
      } else if (val == 3) {
        (window.location.href = `${environment.cannabisHost}/admin/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`),
          "_blank";
      } else if (val == 6) {
        (window.location.href = `${environment.busniessHost}/admin/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`),
          "_blank";
      } else if (val == 7) {
        window.location.href = environment.dogUrl;
      }else if (val == 8) {
        window.location.reload();
      }
    }
    backToLogin(){
      const encryptedData =  sessionStorage.getItem('encryptedData');
      window.location.href = `${environment.alarmPermitHost}?data=${encryptedData}`;
    }
  intervalID:any;
  getUserDetail():any{
    this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
      this.currentUser = user ? user : null;
      console.log("user Data",this.currentUser)

      if( this.currentUser){
        console.log("user Data")
        clearInterval(this.intervalID);
      }
    })
    );
  }
  interval(){
     this.intervalID = setInterval(() => {
      this.getUserDetail()
    }, 2000);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClickLogout() {
    this.authenticationService.logout();
    this.toasterService.success(appToaster.logoutSuccess);  
    this.href = environment.alarmPermitHost
        
    // this.router.navigate(['auth/sign-in'], {queryParams : {type : 'admin'}});
  }

  navigateToCityAdmin() {
    this.router.navigate(['admin/city-admin']);
  }

  navigateToStaffAdmin() {
    this.router.navigate(['admin/staff']);
  }

  navigateToAdminIndexPage() {
    this.router.navigate(['admin/index']);
  }

  signatureUpload() {
    const formData = new FormData();
    this.isSubmit = true;
    if (this.selectedFile) {
      formData.append('signature', this.selectedFile );
      this.subscription.add(this.adminService.managerUploadSignature(formData).subscribe((data: any) => {
        this.authenticationService.setUser(data);
        this.toasterService.success("Signature uploaded successfully");
        this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
          this.currentUser = user ? user : null;
        }));
        this.signatureUpl.nativeElement.click();
        this.clear();
      }));
    } else {
      return false;
    }
  }

  clear() {
    this.isSubmit = false;
    this.isSignatureUploaded = false;
    this.selectedFile = null;
    this.signatureFileName = '';
  }

  selectFile(event) {
    this.selectedFile = event.target.files[0];
    this.signatureFileName = event.target.files[0].name;
    this.isSignatureUploaded = true
  }

  openUploadedSignature() {
    if (this.currentUser.signature) {
      window.open(environment.s3Path + environment.imageBasePath + this.currentUser.signature, '_blank');
    }
  }

  deleteUploadedSignature() {
    if (this.currentUser.signature && this.currentUser.signature != 'null') {
      const formData = new FormData();
      formData.append('signature', null );
      this.subscription.add(this.adminService.managerUploadSignature(formData).subscribe((data: any) => {
        this.authenticationService.setUser(data);
        this.toasterService.success('Uploaded signature deleted successfully');
        this.subscription.add(this.authenticationService.getUserInfo().subscribe(user => {
          this.currentUser = user ? user : null;
        })
        );
      }));
      this.signatureUpl.nativeElement.click();
    }
  }

  selectFiles(event) {
    this.selectedFile = event.target.files[0];
    this.errorMessage = '';
  }
  clearSelectedFile() {
    this.selectedFile = null;
    this.errorMessage = '';
  }

  uploadAddress() {
    this.selectedFile = null;
  }
  uploadAddressFile() {
    if (this.errorMessage != '' || !this.selectedFile) {
      return false;
    }
    this.hideModal = true;
    let formData = new FormData();
    formData.append('city_address', this.selectedFile);
    // formData.append('id', this.currentUser.id);
    // let payload = {
    //   id: this.currentUser.id,
    //   city_address: this.selectedFile
    // }
    this.subscription.add(this.adminService.saveAddressCSVFile(formData).subscribe((data: any) => {
      this.toasterService.success("Address uploaded successfully");
      this.selectedFile = null;
      this.hideModal = false;
    }, (error: any) => {
      this.selectedFile = null;
      this.hideModal = false;
    }));
  }
}
