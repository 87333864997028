import { ApplicationService } from "src/app/core/http/users/application.service";
import { AuthenticationService } from "./../../../authentication/authentication.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { appToaster } from "src/app/configs";
import { UsersService } from "src/app/core/services";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public currentUser;
  public isApplicationEditable = false;
  public isFormActive = false;
  private subscription: Subscription = new Subscription();
  public activeRoute: any;
  public currentApplication = null;
  public showSaveAndExit = false;
  public paymentAllCompleted = false;
  public event: any;
  public currentRoute: any;
  public isAdmin = false;
  public showExit: any;
  public newValue: any;
  public application_Type: any;
  public headerShow: string = "";
  saveExitButton1 = true;
  public href: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public authenticationService: AuthenticationService,
    private toasterService: ToastrService,
    private userService: UsersService,
    private applicationService: ApplicationService
  ) {
    /** GET CURRENT ROUTE */
    this.currentRoute = this.router.url;
    this.application_Type = localStorage.getItem("application_type");
    this.subscription.add(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.showExit = this.userService.getCurrentApplication();
          setTimeout(() => {
            this.headerShow = localStorage.getItem("header");
          }, 1000);
          this.newValue =
            this.showExit == null ? false : this.showExit.response.id;
          this.event = event;
          this.subscription.add(
            this.applicationService.obsApplicationData.subscribe((data) => {
              if (data) {
                console.log("rou", this.activeRoute[3]);
                this.currentApplication = data;
                console.log("header response==>", this.currentApplication);
                this.setConditions(this.event);
              } else {
                this.currentApplication =
                  this.userService.getCurrentApplication();
                this.setConditions(this.event);
              }
            })
          );
        }
      })
    );
  }

  ngOnInit() {
    this.currentRoute = this.router.url;
    this.subscription.add(
      this.userService.userDetailsObs.subscribe((data: any) => {
        if (data) {
          this.currentUser = data ? data : null;
          console.log("data is ==>", this.currentUser);
        } else {
          if (this)
            this.subscription.add(
              this.authenticationService.getUserInfo().subscribe((user) => {
                this.currentUser = user ? user : null;
              })
            );
        }
      })
    );
    if (this.event) {
      this.subscription.add(
        this.applicationService.obsApplicationData.subscribe((data) => {
          if (data) {
            this.currentApplication = data;
            this.setConditions(this.event);
          } else {
            this.currentApplication = this.userService.getCurrentApplication();
            this.setConditions(this.event);
          }
        })
      );
    }
  }
  exit() {
    this.router.navigate(["user/index"]);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  goToLogin() {
    // this.router.navigateByUrl('auth/sign-in');
    this.href = environment.alarmPermitHost;
  }

  onClickProfile() {
    this.router.navigateByUrl("user/update-profile");
  }

  onClickLogout() {
    this.authenticationService.logout();
    this.toasterService.success(appToaster.logoutSuccess);
    this.goToLogin();
  }

  onClickSaveAndExit() {
    // if (this.activeRoute[this.activeRoute.length - 1] == 'review' || this.activeRoute[this.activeRoute.length - 1].includes('additional-info')) {
    //   this.router.navigate(['user/index']);
    // } else {
    //   this.applicationService.setSaveAndExit(true);
    //   this.userService.isSaveAndExit.next({ isSaveAndExit: true, currentForm: this.activeRoute[this.activeRoute.length - 1] });;
    // }

    if (this.activeRoute[this.activeRoute.length - 1] == "review") {
      this.router.navigate(["user/index"]);
    } else if (
      this.activeRoute[this.activeRoute.length - 1].includes("additional-info")
    ) {
      this.applicationService.setSaveAndExit(true);
      this.userService.isSaveAndExit.next({
        isSaveAndExit: true,
        currentForm: "addedQuestionsForm",
      });
    } else {
      this.applicationService.setSaveAndExit(true);
      this.userService.isSaveAndExit.next({
        isSaveAndExit: true,
        currentForm: this.activeRoute[this.activeRoute.length - 1],
      });
    }
  }

  navigateToIndexPage() {
    console.log("fu call");
    this.router.navigate(["user/index"]);
  }
  navigateToIndex() {
    window.location.reload();
  }

  onClickACHAccount() {
    this.router.navigate(["user/ach/index"]);
  }

  navigateToPaymentPage() {
    this.currentApplication = this.userService.getCurrentApplication();
    if (this.currentApplication.response.application_type == 1) {
      if (this.currentApplication.response.tw_hauler_details.length > 0) {
        this.router.navigate(["user/application/payment"], {
          queryParams: {
            applicationId: this.currentApplication.response.id,
            feeType: this.checkFeeType(this.currentApplication.response),
          },
        });
      }
    } else if (
      this.currentApplication.response.application_type == 2 ||
      this.currentApplication.response.application_type == 3
    ) {
      if (
        Object.keys(this.currentApplication.response.fp_premises_detail)
          .length > 0
      ) {
        this.router.navigate(["user/application/payment"], {
          queryParams: {
            applicationId: this.currentApplication.response.id,
            feeType: this.checkFeeType(this.currentApplication.response),
          },
        });
      }
    }

    // else if (this.currentApplication.response.cn_acknowledgements && this.currentApplication.response.cn_ownership_detail.length == 0) {
    //   this.toasterService.error('Please add Owners / Officers')
    // }
  }

  checkFeeType(application) {
    if (
      application.application_payment_data &&
      application.application_payment_data.length > 0
    ) {
      application.application_payment_data.forEach((element) => {
        if (element.fee_type == 0 && element.status == 3) {
          return 1;
        } else if (
          element.fee_type == 0 &&
          (element.status == 0 || element.status == null)
        ) {
          return 0;
        }
      });
    } else {
      return 0;
    }
  }

  setConditions(event) {
    this.currentRoute = this.router.url;
    // this.saveExitButton1 =false;
    if (
      this.currentApplication &&
      this.currentApplication.response.tw_payment_details &&
      this.currentApplication.response.tw_payment_details.length > 0
    ) {
      const found = this.currentApplication.response.tw_payment_details.filter(
        (el) => el["status"] == null || el["status"] == 0 || el["status"] == 2
      );
      if (found.length == 0) {
        this.paymentAllCompleted = true;
      }
    }
    this.activeRoute = event.url.split("/");
    console.log("event is==>", event);
    console.log("event is==>", this.activeRoute);

    this.isAdmin =
      this.activeRoute[this.activeRoute.length - 1].includes("admin=true");
    if (
      this.activeRoute[3] &&
      this.activeRoute[3].includes("what") &&
      !this.currentApplication
    ) {
      this.showSaveAndExit = false;
    } else if (
      this.currentApplication &&
      this.currentApplication.response.id &&
      this.activeRoute[3] &&
      this.activeRoute[3].includes("what")
    ) {
      this.showSaveAndExit = true;
    } else if (this.activeRoute[3] && !this.activeRoute[3].includes("what")) {
      this.showSaveAndExit = true;
    } else if (
      this.activeRoute[3] &&
      this.activeRoute[3].includes("what") &&
      this.currentApplication &&
      !this.currentApplication.response.id
    ) {
      this.showSaveAndExit = false;
    }
    if (
      this.activeRoute[2] == "application" &&
      !this.activeRoute[this.activeRoute.length - 1].includes("payment")
    ) {
      this.isFormActive = true;
    } else {
      this.isFormActive = false;
    }
  }

  OnClickResubmit() {
    this.applicationService.setSaveAndExit(true);
    this.userService.isSaveAndExit.next({
      isSaveAndExit: true,
      currentForm: this.activeRoute[this.activeRoute.length - 1],
    });
    this.subscription.add(
      this.applicationService
        .resubmitApplication({
          application_id: this.currentApplication.response.id,
        })
        .subscribe((data) => {
          this.router.navigate(["user/index"]);
        })
    );
  }

  onClickExit() {
    this.router.navigate(["admin/index"], { queryParams: { type: 1 } });
  }

  switchToOtherPortal(val) {
    // this.authenticationService.logout();
    const encryptedData =  sessionStorage.getItem('encryptedData');
    const csrfToken = sessionStorage.getItem('csrfToken');

    console.log("csrf token ===>", csrfToken);
    if (val == 2) {
      (window.location.href = `${environment.alarmPermitHost}user/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`),
        "_blank";
    } else if (val == 3) {
      (window.location.href = `${environment.cannabisHost}user/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`),
        "_blank";
    } else if (val == 6) {
      (window.location.href = `${environment.busniessHost}user/index?csrfToken=${csrfToken}&encryptedData=${encryptedData}`),
        "_blank";
    } else if (val == 7) {
      window.location.href = environment.dogUrl;
    }
  }
  applicationSubmit() {
    this.subscription.add(
      this.userService
        .applicationSubmit({
          application_id: this.currentApplication.response.id,
        })
        .subscribe((data) => {
          this.router.navigate(["user/index"]);
        })
    );
  }
  getCSRFtoken() {
    this.subscription.add(
      this.authenticationService.getCSRFToken().subscribe((data: any) => {
        sessionStorage.setItem("csrfToken", data);
        console.log("csrf token value is ");
      })
    );
  }
  /// no fire fee submit//
  noFireFeeSubmit() {
    this.subscription.add(
      this.userService
        .noFireFeeSubmit({
          application_id: this.currentApplication.response.id,
        })
        .subscribe((data) => {
          this.router.navigate(["user/index"]);
        })
    );
  }
  backToLogin(){
    const encryptedData =  sessionStorage.getItem('encryptedData');
    window.location.href = `${environment.alarmPermitHost}?data=${encryptedData}`;
  }
  onClickCE(platformKey) {
    const encryptedData =  sessionStorage.getItem('encryptedData');
    if (platformKey == 2) {
          window.open(`${environment.ceUrl}` + `?user=user_login`);
    }else{
      window.open(
        `${environment.ceUrl}auth/verify-ce-login?csrfToken=${encryptedData}&platformKey=${platformKey}`
        )
    }
  }
}
